

import { Vue, Component } from 'vue-property-decorator'
import { MachineList } from '@/types/machineryManage'

@Component
export default class InsectPest extends Vue {
  private info = {
    strategyName: '',
    strategyState: '',
    machineryType: '',
    machineryState: ''
  }

  private loading = false
  private tableData: MachineList[] = []

  private total = 0
  private page = 1
  private size = 10
  states = ['空闲', '使用中', '待维修', '维保中', '终止使用']
  spanColumn: any = []
  spanColumn1: any = []
  spanColumn21: any = []
  showDialog = false
  qrType = '1'
  code = ''
  get id () {
    return this.$route.query.id as string
  }

  get isForBiddenUse () {
    return this.info.machineryState === '5'
  }

  created () {
    this.loadData()
    this.loadTable()
  }

  loadData () {
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByMachineryId, {
      machineryId: this.id
    }).then(res => {
      this.info = res || []
    })
  }

  loadTable () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectStrategyByMachineryIdApp, {
      machineryId: this.id
    }).then(res => {
      let count = 0
      let count1 = 0 // 统计所有
      let count2 = 0 // 统计所有
      res.forEach((item: any, index: number) => {
        count1 += count
        count2 = count1
        count = 0
        if (item.childNode && item.childNode.length > 0) {
          item.childNode.forEach((item1: any, index1: number) => {
            if (item1.childNode && item1.childNode.length > 0) {
              item1.childNode.forEach((item2: any, index2: number) => {
                // 统计2,3层树结构
                count++
                this.tableData.push(item2)
                // 第一列
                if (item.childNode.length - 1 === index1 && item1.childNode.length - 1 === index2) {
                  this.spanColumn.push([count1, count])
                }
                if (item1.childNode.length - 1 === index2) {
                  if (item1.childNode.length > 1) {
                    this.spanColumn1.push([count2, item1.childNode.length])
                  } else {
                    this.spanColumn21.push(count1 + count - 1)
                  }
                  count2 = count2 + item1.childNode.length
                }
              })
            }
          })
        }
      })
    }).finally(() => {
      this.loading = false
    })
  }

  goEdit () {
    this.$router.push({ name: 'machineAdd', query: { machineryId: this.id } })
  }

  onRepair () {
    this.$router.push({
      path: '/machineryManage/repair',
      query: {
        machineryType: this.info.machineryType,
        machineryId: this.id
      }
    })
  }

  onKeep () {
    this.$router.push({
      path: '/machineryManage/upkeep',
      query: {
        machineryType: this.info.machineryType,
        machineryId: this.id
      }
    })
  }

  // 使用
  onUse () {
    this.$axios.post(this.$apis.machineryManage.updateYhMachineryInfoState, {
      machineryId: this.id
    }).then(res => {
      this.$message.success('设置成功')
      this.loadData()
    })
  }

  // 删除
  onDelete () {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.deleteYhMachineryInfo, {
        machineryId: this.id
      }).then(res => {
        this.$message.success('删除成功')
        this.$router.back()
      })
    })
  }

  // 下载
  onDownLoad (qrType: string | any, preview: boolean) {
    if (qrType) {
      this.qrType = qrType === '1' ? '2' : '1'
    }
    this.showDialog = true
    this.$axios.downloadFile(this.$apis.machineryManage.downloadQR, {
      qrType: this.qrType,
      machineryId: this.id,
      params: {
        preview: preview
      }
    }).then((res) => {
      if (preview) {
        this.code = res
      }
    })
  }

  // 保养策略合并
  objectSpanMethod ({ row, column, rowIndex, columnIndex }: any) {
    const index: any = this.spanColumn.findIndex((item: any) => {
      return item[0] === rowIndex
    })
    const index1: any = this.spanColumn1.findIndex((item: any) => {
      return item[0] === rowIndex
    })
    if (columnIndex === 0) {
      if (this.spanColumn[index] && rowIndex === this.spanColumn[index][0]) {
        return {
          rowspan: this.spanColumn[index][1],
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }

    if (columnIndex === 1) {
      if (this.spanColumn1[index1] && rowIndex === this.spanColumn1[index1][0]) {
        return {
          rowspan: this.spanColumn1[index1][1],
          colspan: 1
        }
      } else {
        if (this.spanColumn21.indexOf(rowIndex) === -1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }

  tableRowStyle ({ row, rowIndex }: any) {
    if (+row.strategyUsedRatio >= 100) {
      return { color: 'red' }
    }
  }
}
